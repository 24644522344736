import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useWindowScroll } from 'react-use';
import { ContentContext } from 'contexts/ContentContext';

// Images
import logo from 'img/wsm-logo-horizontal-white.svg'

import './index.scss'

export function Header() {
  const [isActive, setIsActive] = useState(false),
        [dropdown, setDropdown] = useState(),
        { y } = useWindowScroll(),
        { properties, loaded } = useContext(ContentContext);

  useEffect(()=> {
    setDropdown(false);
    if (y === 0) setIsActive(false);
  }, [y])
  return (
    <React.Fragment>
    <div className={`header ${y > 0 || isActive ? "header-shadow" : ''}`}>
      <Link to='/'>
        <img className="header-logo"src={logo} />
      </Link>
      <svg className={`hamburger ${isActive ? "cross" : ''}`} onClick={() => setIsActive(!isActive)} viewBox="0 0 800 600">
        <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
        <path d="M300,320 L540,320" id="middle"></path>
        <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
      </svg>
      <nav className="desktop-links">
        <Link to="/" onClick={() => setIsActive(false)} onMouseOver={() => {setDropdown(''); setIsActive(true)}}>Home</Link>
        <div className='' onMouseOver={() => {setDropdown('properties'); setIsActive(true)}}>
          <Link to="/properties" onClick={() => setIsActive(false)}>Properties</Link>
          <ol className={`dropdown properties-dropdown ${dropdown === 'properties' ? "dropdown-active" : ''}`} onMouseOut={() => {setDropdown(null)}}>
            {loaded ? properties.map((property, i) => (
              <li className='dropdown-item' key={i}><Link to={`/properties/${property.slug}`} onClick={() => {setIsActive(false); setDropdown(null)}}>{property.name}</Link></li>
              )) : 
              <li className='dropdown-item'>Loading...</li>
            }
            <li className='dropdown-item'><Link to="/properties" onClick={() => {setIsActive(false); setDropdown(null)}}>See all</Link></li>
          </ol>
        </div>
        <div className='' onMouseOver={() => setDropdown('about')}>
          <Link to="/about" onClick={() => setIsActive(false)}>About</Link>
          <ol className={`dropdown about-dropdown ${dropdown === 'about' ? "dropdown-active" : ''}`} onMouseOut={() => {setDropdown(null)}}>
            <li className='dropdown-item'><Link to="/about" onClick={() => {setIsActive(false); setDropdown(null)}}>About WSP</Link></li>
            <li className='dropdown-item'><Link to="/about/professionals" onClick={() => {setIsActive(false); setDropdown(null)}}>Professionals</Link></li>
            <li className='dropdown-item'><Link to="/about/board" onClick={() => {setIsActive(false); setDropdown(null)}}>Advisory Board</Link></li>
          </ol>
        </div>
        <Link to="/#contact" onClick={() => setIsActive(false)} onMouseOver={() => setDropdown('')}>Contact</Link>
      </nav>
    </div>
    <nav className={`mobile-nav ${isActive ? "isActive" : ''} ${y > 0 ? "header-shadow" : ''}`}>
      <Link to="/" onClick={() => setIsActive(false)}>Home</Link>
      <Link to="/about" onClick={() => setIsActive(false)}>About</Link>
        <Link className='mobile-nav-sublink' to="/about" onClick={() => {setIsActive(false); setDropdown(null)}}>About WSP</Link>
        <Link className='mobile-nav-sublink' to="/about/professionals" onClick={() => {setIsActive(false); setDropdown(null)}}>Professionals</Link>
        <Link className='mobile-nav-sublink' to="/about/board" onClick={() => {setIsActive(false); setDropdown(null)}}>Advisory Board</Link>
      <Link to="/properties" onClick={() => setIsActive(false)}>Properties</Link>
      <Link to="/#contact" onClick={() => setIsActive(false)}>Contact</Link>
    </nav>
    </React.Fragment>
  );
}