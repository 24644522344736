import React, { useState, useEffect } from 'react';
import { getData } from 'utils/sanity/'

// Queries
import { propertiesQuery, membersQuery, advisorsQuery, aboutQuery } from "utils/sanity/sanity-queries"

const ContentContext = React.createContext({});

const ContentProvider = (props) => {
  // Set two loading states for without and with data
  const [loaded, setLoaded] = useState(false);
  // Set a project state
  const [properties, setProperties] = useState([]);
  const [members, setMembers] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [about, setAbout] = useState([]);
  const [videoLoaded, setVideoLoaded] = useState(false);

  // Get the data from Sanity
  useEffect(() => {
    async function fetchData() { 
      try {
        // Get Properties
        const propertiesResponse = await getData(propertiesQuery)
        setProperties(propertiesResponse)
        // Get Properties
        const membersResponse = await getData(membersQuery)
        setMembers(membersResponse)
        // Get Advisors
        const advisorsResponse = await getData(advisorsQuery)
        setAdvisors(advisorsResponse)
        // Get Content
        const aboutResponse = await getData(aboutQuery)
        setAbout(aboutResponse)
        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

  return (
    <ContentContext.Provider value={{ videoLoaded, setVideoLoaded, loaded, properties, members, advisors, about }}>
      {props.children}
    </ContentContext.Provider>
  );
}

export { ContentContext, ContentProvider };