export const membersQuery = `*[_type=="member"] | order(priority asc) {
  name,
  title,
  "img": image.asset -> url,
  bio
}`

export const advisorsQuery = `*[_type=="advisor"] | order(priority asc) {
  name,
  title,
  bio
}`

export const aboutQuery = `*[_type=="about"][0] | {
  title,
  subtitle,
  content
}`

export const propertiesQuery = `*[_type=="property"] | {
  name,
  "thumb": thumb.asset -> url, 
  address,
  assetType,
  "slug": slug.current,
  images[]{"img": asset -> url},
  description,
  location
}`