import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

// DOM Packages
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ScrollToTop } from './utils/ScrollToTop'

// Components
import { Header } from 'components/Header'
import { Footer } from 'components/Footer' 

// Contexts
import { ContentProvider } from 'contexts/ContentContext';

// Style
import 'style/index.scss'

import * as serviceWorker from './serviceWorker';

// Pages
const Home = lazy(async () => (await import('pages/Home')));
const About = lazy(async () => (await import('pages/About')));
const Properties = lazy(async () => (await import('pages/Properties')));
const PropertyPage = lazy(async () => (await import('pages/PropertyPage')));

function App() {
  return(
    <ContentProvider>
      <Suspense fallback={    
        <div className="full-page-loader">
          <svg width="103" height="74" viewBox="0 0 103 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 52.2134C31.06 27.6834 41.31 24.0734 44.09 26.6834C47.15 29.5234 41.09 39.6834 44.37 42.0234C49.33 45.5434 67.93 25.0734 72.84 28.7734C75.28 30.5934 72 36.6234 74.51 38.7734C77.79 41.6334 88.04 35.5334 98.51 27.8934C81.42 46.7534 74.3 50.0734 71.3 48.3034C68.68 46.7834 69.57 41.5934 66.42 40.4934C63.27 39.3934 60.72 44.2034 53.02 47.6134C51.2 48.4134 41.95 52.5034 37.81 49.0034C34.06 45.8334 37.74 39.1634 35.06 36.5634C32.38 33.9634 23.87 35.8134 0 52.2134Z" fill="#0E97AF"/>
            <path d="M2 27.1234C32.15 1.42344 42.31 -1.87656 45.53 0.743437C49.28 3.84344 44.28 15.5834 47.72 17.1634C52.21 19.2334 67.29 2.23344 72.88 3.16344C73.3413 3.2015 73.7837 3.36393 74.16 3.63344C76.34 5.29344 73.81 9.95344 75.71 12.2034C76.92 13.6534 79.39 13.4334 80.96 13.2734C89.17 12.4434 97.15 5.75344 100.42 2.78344C83.3 21.4234 76.42 24.4234 73.5 22.7334C71.03 21.2834 71.69 16.4634 68.6 15.4434C65.72 14.4434 63.52 18.0734 57.35 21.2634C56.04 21.9334 45.44 27.3934 40.6 23.7734C36.66 20.7734 39.84 14.2934 36.93 11.6334C34.02 8.97344 25.45 10.6134 2 27.1234Z" fill="#286EB4"/>
            <path d="M11.4399 69.9534C27.5199 54.3534 34.7499 51.9534 38.0899 53.9534C41.7199 56.0734 40.6499 63.2334 44.2299 64.0734C47.1499 64.7734 49.0699 60.3134 57.0699 55.8434C60.1699 54.1134 65.1499 51.3334 67.3999 53.0534C69.8899 54.9534 66.7399 60.6234 68.5099 62.2634C70.5099 64.1534 78.6899 60.2634 99.9099 43.0034C97.2699 46.4534 80.5099 68.1734 69.8399 70.2534C68.2899 70.5534 64.3399 71.3234 62.3699 69.2534C60.3999 67.1834 62.0499 64.1334 60.7299 63.2534C57.7299 61.2534 47.3299 76.1534 40.6199 73.7034C38.1199 72.7834 36.6199 69.6234 36.1899 68.7034C34.2699 64.7034 35.8299 61.9734 34.6499 60.7034C33.2599 59.1634 28.1999 59.7334 11.4399 69.9534Z" fill="#B0BDE0"/>
          </svg>  
        </div> 
      }>
        <Router>
          <ScrollToTop />
            <Header />
            <Switch>
              <Route exact path="/about/:slug" component={ About }/>
              <Route path="/about" component={ About }/>
              <Route exact path="/properties" component={ Properties }/>
              <Route exact path="/properties/:slug" component={ PropertyPage }/>
              <Route path="/" component={ Home }/>
            </Switch>
            <Footer />
        </Router>
      </Suspense>
    </ContentProvider>
  )
}

window.onload = () => {
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
